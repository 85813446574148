var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "enkrypt-promo-snackbar" },
    [
      _c(
        "v-snackbar",
        {
          staticClass: "pl-sm-5",
          attrs: {
            value: _vm.enkryptWalletSnackbar,
            "max-width": "380px",
            "min-width": "auto",
            timeout: -1,
            light: "",
            left: "",
            rounded: "",
            transition: "scale-transition",
          },
        },
        [
          _c(
            "v-btn",
            { staticClass: "close-button", attrs: { icon: "" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "cursor--pointer",
                  attrs: { medium: "", color: "greyMedium" },
                  on: { click: _vm.closeSnackbar },
                },
                [_vm._v(" mdi-close ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pa-6" }, [
            _c("img", {
              staticClass: "mb-6",
              attrs: {
                alt: "enkrypt logo",
                src: require("@/assets/images/icons/enkrypt/icon-enkrypt-colored.svg"),
                height: "20px",
              },
            }),
            _c("div", { staticClass: "mew-body mb-6 mb-sm-7" }, [
              _vm._v(
                " Access your wallet and switch accounts in seconds with our official browser extension "
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "btn-container d-flex flex-column-reverse flex-sm-row justify-space-between align-center",
              },
              [
                _c(
                  "mew-button",
                  {
                    staticClass: "ml-sm-n4 mt-1 mt-sm-0",
                    attrs: {
                      "color-theme": "#939fb9",
                      "btn-style": "transparent",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openHelpCenter.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Learn More")]
                ),
                _c(
                  "mew-button",
                  {
                    staticClass: "extension-btn",
                    staticStyle: { "border-radius": "40px !important" },
                    attrs: {
                      "color-theme": "#7E44F2",
                      "btn-link": "https://www.enkrypt.com",
                      rel: "dofollow",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-3",
                      attrs: {
                        src: _vm.browserLogo,
                        alt: "chrome",
                        width: "25px",
                        height: "25px",
                      },
                    }),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(" " + _vm._s(_vm.text) + " "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }